import React, { useState } from 'react';
import './subscriptions.css';
import axios from 'axios';
import { useAuth } from '../../components/security/AuthContext';

function Subscriptions() {
  const [showPopup, setShowPopup] = useState(false);
  const [showShareCodePopup, setShowShareCodePopup] = useState(false);
  const [mpesaCode, setMpesaCode] = useState('');
  const [timestamp, setTimestamp] = useState('');
  // eslint-disable-next-line
  const [selectedPlan, setSelectedPlan] = useState({});
  const [amount, setAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const authContext = useAuth();

  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const formatPhoneNumber = (number) => {
    if (number.startsWith('07')) {
      return '254' + number.slice(1);
    } else if (number.startsWith('01')) {
      return '254' + number.slice(1);
    }
    return number;
  };

  const handlePayClick = async (e) => {
    e.preventDefault();

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber || authContext.userDetails?.mobile);
    const requestData = {
      PhoneNumber: formattedPhoneNumber,
      Amount: amount,
    };

    try {
      const response = await axios.post('https://api.writersturing.com/mobile-money/stk-transaction-request', requestData, {
        headers: {
          'Authorization': authContext.token
        }
      });
      setAlertMessage('Transaction request sent successfully.');
      setShowAlert(true);
      console.log(response.data);
    } catch (error) {
      setAlertMessage('Failed to send transaction request.');
      setShowAlert(true);
      console.error(error);
    }
    setTimeout(() => setShowAlert(false), 7000);
  };

  const handleShareCodeClick = () => {
    setShowShareCodePopup(true);
  };

  const handleShareCodeSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      username: authContext.userDetails?.username,
      phoneNumber: phoneNumber || authContext.userDetails?.mobile,
      mpesaCode: mpesaCode,
      time: timestamp,
    };

    try {
      const response = await axios.post('https://api.writersturing.com/writersturing/user/share-code', requestData, {
        headers: {
          'Authorization': authContext.token
        }
      });
      setAlertMessage('Mpesa Code shared successfully for Upgrade. Thank You!');
      setShowAlert(true);
      console.log(response.data);
    } catch (error) {
      setAlertMessage('Failed to share code.');
      setShowAlert(true);
      console.error(error);
    }
    setShowShareCodePopup(false);
    setTimeout(() => setShowAlert(false), 7000);
  };

  const plans = [
    {
      name: 'Premium Account',
      price: 'KES 450',
      features: ['Lifetime Job Bidding', 'Accessing Job Posts', 'Get Free Notifications']
    },
    // {
    //   name: 'Elite',
    //   price: 'KES 750',
    //   features: ['Lifetime Job Bidding', 'Accessing New Job Posts', 'Available Jobs Alerts', 'Get SMS Notifications', 'Email Notifications', 'Quick 24/hr Support']
    // },
    // {
    //   name: 'Monthly Plan',
    //   price: 'KES 959 / 1mth',
    //   features: ['Unlimited Job Bidding', 'Available Job Alerts', 'Accessing New Job Posts', 'Quick 24/hr Support', 'Get SMS Notifications', 'Email Notifications']
    // }
  ];

  const togglePopup = (plan) => {
    setSelectedPlan(plan);
    setAmount(plan.price.split(' ')[1]);
    setPhoneNumber(authContext.userDetails?.mobile || '');
    setShowPopup(!showPopup);
  };

  return (
    <div className='user-mainuser-container'>
      <div className="subscriptions-page">
        <h1 className='plans-heading'>Purchase Writing Account</h1>
        <div className="plans-container">
          {plans.map((plan, index) => (
            <div className="plan-card" key={index}>
              <h2>{plan.name}</h2>
              <p className='payment-price'>{plan.price}</p>
              <ul>
                {plan.features.map((feature, index) => (
                  <p className='payment-paragraph' key={index}>{feature}</p>
                ))}
              </ul>
              <button onClick={() => togglePopup(plan)}>Purchase</button>
            </div>
          ))}
        </div>

        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <span className="close-btn" onClick={() => setShowPopup(false)}>X</span>
              <h2>Payment Methods</h2>
              <p className='payment-paragraph'>Pay via Mpesa. You will receive an STK push on your phone for you to enter your pin. 
              <br /> <strong>If activation delays share Transaction code for automatic activation.</strong></p>
              <div className="payment-details">
                <label className='payment-form-detail' htmlFor="phone">Phone Number:</label>
                <input type="tel" id="phone" value={phoneNumber || authContext.userDetails?.mobile || ''} onChange={(e) => setPhoneNumber(e.target.value)} />
                <label className='payment-form-detail' htmlFor="amount">Amount:</label>
                <input type="text" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} disabled />
              </div>
              <div className="payment-buttons">
                <button className="close-btn" onClick={() => setShowPopup(false)}>X</button>
                <button className="pay-btn" onClick={handlePayClick}>Pay Now</button>
                <button className="share-code-btn" onClick={handleShareCodeClick}>Share Code</button>
              </div>
              {showAlert && <div className="instructions-notification-popup">{alertMessage}</div>}
              <hr />
              <p className="alternative-payment">
                <strong>Alternatively, (WRITERS TURING)</strong>
                <ol className='alternative-list'>
                  <li>Go to Mpesa - Lipa na Mpesa - Pay Bill</li>
                  <li>Business no: <strong>4137193</strong></li>
                  <li>Account no: <strong>Your username</strong></li>
                  <li>Amount: <strong>{amount}</strong></li>
                  <li>Enter your M-pesa PIN</li>
                </ol>
              </p>
              <hr />
            </div>
          </div>
        )}

        {showShareCodePopup && (
          <div className="share-code-popup">
            <div className="share-code-popup-content">
              <span className="close-btn" onClick={() => setShowShareCodePopup(false)}>X</span>
              <h2>Share Mpesa Code</h2>
              <form onSubmit={handleShareCodeSubmit}>
              <div className="instructions-input-group">
                <label   htmlFor="transaction-code">Transaction Code:</label>
                <input placeholder="Enter M-PESA code i.e. CM78N9W4D" type="text" id="mpesaCode" value={mpesaCode} onChange={(e) => setMpesaCode(e.target.value)} className="instructions-input-field" required />
                <label  htmlFor="timestamp">Timestamp:</label>
                <input type="datetime-local" id="timestamp" value={timestamp} onChange={(e) => setTimestamp(e.target.value)} className="instructions-input-field" required />
                </div>
                <div className="share-code-buttons">
                  <button className="close-btn" type="button" onClick={() => setShowShareCodePopup(false)}>X</button>
                  <button className="share-btn" type="submit">Send</button>
                </div>
              </form>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

export default Subscriptions;
