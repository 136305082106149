import axios from 'axios';

export const apiClient = axios.create({
    baseURL: 'https://api.writersturing.com',
});

export const fetchJobs = () => apiClient.get('/api/jobs');
export const createJob = (job) => apiClient.post('/api/jobs', job);
export const deleteUser = (userId) => apiClient.delete(`/writersturing/user/users/${userId}`);
export const getTotalJobs = () => apiClient.get('/api/jobs/total');
export const deleteJob = (jobId) => apiClient.delete(`/api/jobs/${jobId}`);
export const changePassword = async (oldPassword, newPassword) => {
    const response = await axios.post('https://api.writersturing.com/writersturing/admin/change-password', {  
        oldPassword,
        newPassword
    });
    return response.data;
};


export const submitBid = async (bidData) => {
    try {
        const response = await apiClient.post('/api/bids', bidData);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

const formatPhoneNumber = (number) => {
    if (number.startsWith('07')) {
      return '254' + number.slice(1);
    } else if (number.startsWith('01')) {
      return '254' + number.slice(1);
    }
    return number;
  };
  
  export const checkPaymentRecord = async (phoneNumber, token) => { // Accept token as a parameter
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    console.log('Formatted phone number:', formattedPhoneNumber);
    console.log('Request URL:', `https://api.writersturing.com/writersturing/user/payment/${formattedPhoneNumber}`);
    try {
        const response = await axios.get(`https://api.writersturing.com/writersturing/user/payment/${formattedPhoneNumber}`, {
            headers: {
                Authorization: token // Add Authorization header with the token
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error checking payment record:', error);
        throw error;
    }
};


export const updateUserStatus = async (mobile, status) => {
    try {
        const response = await apiClient.post('/writersturing/admin/update-status', { mobile, status });
        return response.data;
    } catch (error) {
        throw new Error('Failed to update user status');
    }
};

export const getUserStatus = (username) => apiClient.get(`/writersturing/user/${username}`);

export const fetchBidsByPhone = (phoneNumber) => {
    return apiClient.get(`/bids/by-phone/${phoneNumber}`);
  };


  export const fetchAllPayments = async () => {
    return apiClient.get('/api/payments');
};


export const fetchAllTraining = async () => {
    return apiClient.get('/api/training');
};


export const deletePayment = async (paymentId) => {
    return apiClient.delete(`/api/payments/${paymentId}`);
};

export const deleteTraining = async (trainingId) => {
    return apiClient.delete(`/api/training/${trainingId}`);
};


export const fetchAllBids = async () => {
    return apiClient.get('/api/bids');
};


export const deleteBid = async (bidId) => {
    return apiClient.delete(`/api/bids/${bidId}`);
};

