import React from 'react';
import { Link } from 'react-router-dom';

function About() {
    return (
      <>
        <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>
        <div className="home-container about">
          <div className="row">
            <div className="col-md-6 text-center">
              <img alt="about" src="./img/img1.png" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <h2 className="main-title about-h2">About Us</h2>
              <p className="main-p">
              Writers Turing is a freelancing platform connecting skilled writers in Kenya to employers across the globe. Employers post private and public projects on the platform while writers bid on the posted projects.
            On our platform we also Virtual Training on different skills and mentorship on different fields. Our learners will go through a sytem and get skilled there after
             get connected to specific employers to hire them for work. Our aim is to connect writers to employers.
              </p>
              <Link to="/signup" className="btn">Get Started</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
  export default About;
  