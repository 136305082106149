import React from 'react';
import './training.css';

function VirtualTraining() {
  return (
    <div className="training-container">
      <header className="training-header">
        <h1 className="training-title">Writers Turing Virtual Training Programs</h1>
        <p className="training-subtitle">Learn And Earn Program-Empower your skills with our expert-led virtual training sessions and get hired immediately.
        <br />
        <strong>Bring Your Laptop or SmartPhone, Let's Learn and Earn.</strong> 
        </p>
      </header>

      <section className="training-description">
        <h2>About Our Training</h2>
        <p>
          Our virtual training programs are designed to provide you with comprehensive knowledge and hands-on experience 
          in freelancing, online writing, and programming. Learn from industry experts and gain the skills you need to succeed in the digital world. Get hired for jobs on completion. 
          <br />
          <strong>Bring Your Laptop or SmartPhone, Let's Learn and Earn.</strong> 
        </p>
      </section>

      <section className="training-types">
        <h2>Types of Training Offered</h2>
        <div className="training-cards">
          <div className="training-card">
            <h3>Freelancing Training</h3>
            <p>Learn the ins and outs of freelancing, from finding clients to managing projects and getting paid.</p>
            <h3>Online Writing</h3>
            <p>Master the art of online writing, including blog posts, articles, and web content that engage and convert readers.</p>
            <h3>Programming</h3>
            <p>Get hands-on training in programming languages like JavaScript, Python, and Java, and build real-world projects.</p>
          </div>
        </div>
      </section>

      <section className="training-payment">
        <h2>Payment Plans</h2>
        <div className="payment-cards">
          <div className="payment-card">
            <h3>Basic Plan</h3>
            <p>Access to all live and recorded sessions</p>
            <p>Recommendations for jobs on completion.</p>
            <p>Exclusive resources and tools</p>
            <p className="payment-price">ksh.350</p>
            {/* <h3>Pro Plan</h3>
            <p>Access to all live and recorded sessions</p>
            <p>One-on-one mentorship</p>
            <p>Recommendations for jobs on completion.</p>
            <p className="payment-price">ksh.650</p>
            <h3>Premium Plan</h3>
            <p>All features of Pro Plan</p>
            <p>Certificate of completion</p>
            <p>Exclusive resources and tools</p>
            <p className="payment-price">ksh.850</p> */}
          </div>
        </div>
      </section>
      <section className="training-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item">
          <h3>What is the duration of each training program?</h3>
          <p>Each training program varies in length, but most range from 2 to 4 weeks. Just create an account with us , register for sessions and get started instantly.</p>
        </div>
        <div className="faq-item">
          <h3>What next after the training?</h3>
          <p>After the training you will join WT Learn and Earn Elite team and get allocated to different employers of our own to be awarded jobs on a daily basis.</p>
        </div>
        <div className="faq-item">
          <h3>Can I get a refund if I'm not satisfied?</h3>
          <p>Yes, we offer a 30-day money-back guarantee if you're not satisfied with the training.</p>
        </div>
        <div className="faq-item">
          <h3>Do I need any prior experience?</h3>
          <p>No prior experience is required. Our training programs are designed for beginners and advanced learners alike.</p>
        </div>
      </section>
    </div>
  );
}

export default VirtualTraining;
