import React, { useState } from 'react';
import '../homemain/main.css'
import Header from '../homemain/Header';
import Sidebar from '../homemain/Sidebar'
import Profile from './Profile';


function Mainprofile() {

  const [openSidebar, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const closeSidebar = () => {
    setOpenSidebar(false);
  };


  return (
    <>
      <div className='user-grid-container'>
        <Header OpenSidebar={toggleSidebar} CloseSidebar={closeSidebar}/>
        <Sidebar openSidebarToggle={openSidebar} CloseSidebar={closeSidebar}/>
        <Profile/>
      </div>

    </>
  )
}

export default Mainprofile