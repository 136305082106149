import React, { useState } from 'react';
import axios from 'axios';
import './stk.css';


function StkPushForm() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      PhoneNumber: phoneNumber,
      Amount: amount,
    };

    try {
      const response = await axios.post('https://api.writersturing.com/mobile-money/stk-transaction-request', requestData);
      setResponseMessage('Transaction request sent successfully.');
      console.log(response.data);
    } catch (error) {
      setResponseMessage('Failed to send transaction request.');
      console.error(error);
    }
  };

  return (
    <div className="stk-push-form">
      <h2>Initiate STK Push</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="amount">Amount:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <button type="submit">Send STK Push</button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
}

export default StkPushForm;
