import React, { useState } from 'react';
import './WithdrawalsPage.css';

const WithdrawalsPage = () => {
  const [amount, setAmount] = useState('');
  const [phone, setPhone] = useState('');
  const [notification, setNotification] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount && phone) {
      setNotification('Withdrawal successful.');
      setTimeout(() => setNotification(null), 3000);
      setAmount('');
      setPhone('');
    }
  };

  return (
    <div className="withdrawals-container">
      {notification && <div className="withdrawals-success-popup">{notification}</div>}
      <div className="withdrawals-notification">
        Please input the correct number registered with our system to successfully receive payments.
      </div>
      <form className="withdrawals-form" onSubmit={handleSubmit}>
        <div className="withdrawals-input-group">
          <label htmlFor="amount" className="withdrawals-label">Amount</label>
          <input
            type="number"
            id="amount"
            name="amount"
            className="withdrawals-input"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="withdrawals-input-group">
          <label htmlFor="phone" className="withdrawals-label">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="withdrawals-input"
            placeholder="Enter phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <button type="submit" className="withdrawals-button">Withdraw</button>
      </form>
    </div>
  );
};

export default WithdrawalsPage;
