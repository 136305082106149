import React, { useState } from 'react';
import { useAuth } from '../../components/security/AuthContext';
import './training.css';
import axios from 'axios';

function Training() {
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showShareCodePopup, setShowShareCodePopup] = useState(false);
  

  const [mpesaCode, setMpesaCode] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const authContext = useAuth();


  const handleShareCodeSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      username: authContext.userDetails?.username,
      phoneNumber: phoneNumber || authContext.userDetails?.mobile,
      mpesaCode: mpesaCode,
      time: mpesaCode,
    };


     // Check if any field is empty
     if (!mpesaCode || !mpesaCode) {
      setAlertMessage("Please input Transaction code and Timestamp");
      setShowAlert(true);
      return;
  }


    try {
      const response = await axios.post('https://api.writersturing.com/writersturing/user/training', requestData, {
        headers: {
          'Authorization': authContext.token
        }
      });
      setAlertMessage('Application Successfull. Thank You! On success you will Recieve an Email from us.');
      setShowAlert(true);

      //clear fields
      setMpesaCode("");
      setTimestamp("");
                

    } catch (error) {
      setAlertMessage('Failed to share code.');
      setShowAlert(true);
      console.error(error);
    }
    setShowShareCodePopup(false);
    setTimeout(() => setShowAlert(false), 7000);
  };


  return (
    <div className='user-training-container'>
      <section className="training-payment">
        <h2>Training Payments</h2>
        <div className="payment-cards">
          <div className="payment-card">
            <h3>Weekly Training</h3>
            <p>Access to live sessions</p>
            <p>Recommendations for jobs on completion.</p>
            <p>Exclusive resources and tools</p>
            <p className="payment-price">ksh.350</p>
          </div>
        </div>
      </section>
      <div className="instructions-card">
        <h2 className="instructions-title">Instructions</h2>
        <p className="instructions-note">NOTE: Registration for training is ONLY via the paybill number specified below.</p>
        <p className="instructions-note">NOTE: Training is conducted every Wednessday at 8PM. </p>
        <ol className="instructions-list">
          <li>Go to Mpesa - Lipa na Mpesa - Pay Bill</li>
          <li>Business no: <strong>4137193</strong></li>
          <li>Account no: <strong>Your username</strong></li>
          <li>Amount <strong>Ksh.350</strong></li>
          <li>Enter M-pesa pin to complete the transaction</li>
          <li>Once done, copy the M-PESA code (i.e. CM78N9W4D) and paste it below and you will get registration confirmation instantly.</li>
        </ol>

        <div className="instructions-input-group">
          <label htmlFor="registered-phone">Registered Phone Number</label>
          <input type="text" id="registered-phone" value={authContext.userDetails?.mobile || ''} className="instructions-input-field" disabled/>
        </div>
        <div className="instructions-input-group">
        <label   htmlFor="transaction-code">Transaction Code:</label>
          <input placeholder="Enter M-PESA code i.e. CM78N9W4D" type="text" id="mpesaCode" value={mpesaCode} onChange={(e) => setMpesaCode(e.target.value)} className="instructions-input-field" required />
          <label  htmlFor="timestamp">Timestamp:</label>
          <input type="datetime-local" id="timestamp" value={timestamp} onChange={(e) => setTimestamp(e.target.value)} className="instructions-input-field" required />
        </div>
        <button className="apply-button" onClick={handleShareCodeSubmit}>Apply</button>
      </div>
      {showAlert && <div className="instructions-notification-popup">{alertMessage}</div>}
    </div>
  );
}

export default Training;
