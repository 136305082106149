import React, { useEffect, useState } from 'react';
import { apiClient, createJob, deleteJob} from '../../components/api/ApiClient';


const Post = () => {
    const [jobs, setJobs] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
   

    
    const handleDeleteJob = async (jobId) => {
        try {
            await deleteJob(jobId);
            setJobs(jobs.filter(job => job.job_id !== jobId));
            setAlertMessage('Job Deleted Successfully.');
            setShowAlert(true);
        } catch (error) {
            console.error('Error deleting job:', error);
            setAlertMessage('Error Deleting Job');
            setShowAlert(true);
        }
        setTimeout(() => setShowAlert(false), 5000);
    };

    

    const [job, setJob] = useState({
        job_title: '',
        job_description: '',
        employer: '',
        duration: '',
        budget: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setJob(prevJob => ({
            ...prevJob,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createJob(job);
            setJob({
                job_title: '',
                job_description: '',
                employer: '',
                duration: '',
                budget: ''
            });
            setAlertMessage('Job Posted Successfully.');
            setShowAlert(true);
        } catch (error) {
            console.error('Error posting job:', error);
            setAlertMessage('Error Posting Job.');
            setShowAlert(true);
        }
        setTimeout(() => setShowAlert(false), 5000);
    };

   
    

    
    useEffect(() => {

        const fetchJobs = async () => {
            try {
                const response = await apiClient.get('/api/jobs');
                setJobs(response.data);
            } catch (error) {
                console.error('Error fetching jobs', error);
            }
        };
        fetchJobs();
       
    }, []);

    return (
        <div className="admin-home-container">
            <div className="admin-home-header">
                <h2>Post Jobs</h2>
                <hr />
            </div>
            <div className="admin-job-form-container">
                <h2>Post a Job</h2>
                <form className="admin-job-form" onSubmit={handleSubmit}>
                    <div className="admin-job-field">
                        <label htmlFor="title">Job Title</label>
                        <input
                            type="text"
                            id="job_title"
                            name="job_title"
                            value={job.job_title}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="admin-job-field">
                        <label htmlFor="description">Job Description</label>
                        <textarea
                            id="job_description"
                            name="job_description"
                            value={job.job_description}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="admin-job-field">
                        <label htmlFor="employer">Employer</label>
                        <input
                            type="text"
                            id="employer"
                            name="employer"
                            value={job.employer}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="admin-job-field">
                        <label htmlFor="duration">Due Date</label>
                        <input
                            type="date"
                            id="duration"
                            name="duration"
                            value={job.duration}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="admin-job-field">
                        <label htmlFor="budget">Budget</label>
                        <input
                            type="text"
                            id="budget"
                            name="budget"
                            value={job.budget}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit" className="admin-submit-button">Post Job</button>
                </form>
            </div>
            <hr />
            <hr />
            <div className="admin-jobs-container">
                <h2>All Jobs</h2>
                <table className="admin-jobs-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Employer</th>
                            <th>Duration</th>
                            <th>Budget</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobs.map((job) => (
                            <tr key={job.job_id}>
                                <td>{job.job_id}</td>
                                <td>{job.job_title}</td>
                                <td>{job.job_description}</td>
                                <td>{job.employer}</td>
                                <td>{job.duration}</td>
                                <td>{job.budget}</td>
                                <td>
                                    <button className="admin-delete-button" onClick={() => handleDeleteJob(job.job_id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <hr />
           
            {showAlert && <div className="instructions-notification-popup">{alertMessage}</div>}
        </div>
    );
};

export default Post;
