import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/security/AuthContext';
import './admin.css'; 

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const authContext = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const success = await authContext.login(username, password);
            if (success) {
                const userRoles = authContext.userDetails.roles;
                if (userRoles.includes('ROLE_ADMIN')) {
                    navigate('/dashboard');
                } else {
                    setErrorMessage('You do not have access to the admin dashboard');
                }
            } else {
                setErrorMessage('Invalid username or password');
            }
        } catch (error) {
            console.error('Error logging in', error);
        }
    };

    return (
        <div className="admin-login-container">
            <div className="admin-login-card">
                <div className="admin-login-header">
                    <h2>Admin Login</h2>
                </div>
                <div className="admin-login-body">
                    {errorMessage && (
                        <div className="admin-login-error">
                            {errorMessage}
                        </div>
                    )}
                    <form onSubmit={handleLogin} className="admin-login-form">
                        <div className="admin-login-field">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="admin-login-field">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="admin-login-button">
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
