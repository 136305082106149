import React, { useState } from 'react';
import { BsJustify, BsWallet } from 'react-icons/bs';
import { useAuth } from '../../components/security/AuthContext';

function UserBar({ OpenSidebar }) {
  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const [notification, setNotification] = useState(null);
  const authContext = useAuth();

  const handleWalletClick = () => {
    setShowWalletDropdown(!showWalletDropdown);
  };

  const handleWalletOptionClick = (option) => {
    setShowWalletDropdown(false);
    setNotification(`Minimum amount to ${option} is Ksh. 1500.`);
    setTimeout(() => setNotification(false), 5000);
  };

  return (
    <div className='user-userbar'>
      <div className='user-menu-icon'>
        <BsJustify className='main-header-user-icon' onClick={OpenSidebar} />
      </div>

      <div className='user-userbar-right'>
        <div className='wallet-icon-container' onClick={handleWalletClick}>
          <BsWallet className='wallet-icon' />
          <span className='wallet-name'>Wallet: Ksh. {authContext.userDetails?.account}</span>
          {showWalletDropdown && (
            <div className='wallet-dropdown'>
              <div className='wallet-option' onClick={() => handleWalletOptionClick('Withdraw')}>
                Withdraw
              </div>
              <div className='wallet-option' onClick={() => handleWalletOptionClick('Transfer')}>
                Transfer Funds
              </div>
            </div>
          )}
        </div>
        {notification && <div className='instructions-notification-popup'>{notification}</div>}
      </div>
    </div>
  );
}

export default UserBar;
