import React, { useState } from 'react';
import { useAuth } from '../../components/security/AuthContext';
import { RiUserFill } from 'react-icons/ri';
import './profile.css';

function Profile() {
  const [selectedImage, setSelectedImage] = useState(null);
  const authContext = useAuth();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='user-mainuser-container'>
      <div className="profile-container">
        <div className="profile-heading">
          <h1 className='profile-paragraph'>Profile</h1>
        </div>
        <div className="profile-card">
          <h2>Profile</h2>
          <div className="profile-info">
            <div className="profile-section">
              <div className="profile-picture">
                {selectedImage ? (
                  <img src={selectedImage} alt="Profile" />
                ) : (
                  <div className="placeholder-image"><RiUserFill /></div>
                )}
              </div>
              <label>Choose picture</label>
              <input type="file" onChange={handleImageChange} accept="image/*" />
            </div>
            <div className="profile-section">
              <label>Rating</label>
              <input type="text" value="1/5" disabled />
            </div>
            <div className="profile-section">
              <label>Username</label>
              <input type="text" value={authContext.username} disabled />
            </div>
            <div className="profile-section">
              <label>First Name</label>
              <input type="text" value={authContext.userDetails?.firstName || ''} disabled />
            </div>
            <div className="profile-section">
              <label>Last Name</label>
              <input type="text" value={authContext.userDetails?.lastName || ''} disabled />
            </div>
            <div className="profile-section">
              <label>Email</label>
              <input type="email" value={authContext.userDetails?.email || ''} disabled />
            </div>
            <div className="profile-section">
              <label>Phone</label>
              <input type="tel" value={authContext.userDetails?.mobile || ''} disabled />
            </div>
            <div className="profile-section">
              <label>Bio</label>
              <textarea rows="7" value={authContext.userDetails?.bio || ''} disabled></textarea>
            </div>
            <div className="profile-section">
              <label>Profile Link</label>
              <input type="text" value="http://writersturing/profile-share" disabled />
            </div>
            <div className="profile-section">
              <label><input type="checkbox" /> Enable Profile Sharing</label>
            </div>
          </div>
          <button className="modern-button">Save</button>
        </div>

        <div className="password-heading">
          <h1 className='profile-paragraph'>Change Password</h1>
        </div>
        <div className="password-card">
          <div className="password-info">
            <div className="password-section">
              <label>Previous Password</label>
              <input type="password" />
            </div>
            <div className="password-section">
              <label>New Password</label>
              <input type="password" />
            </div>
            <div className="password-section">
              <label>Confirm Password</label>
              <input type="password" />
            </div>
            <button>Save Changes</button>
          </div>
        </div>

        <div className="deactivate-heading">
          <h1 className='profile-paragraph'>Deactivate Account</h1>
        </div>
        <div className="deactivate-card">
          <div className="deactivate-info">
            <p className='profile-paragraph'>{authContext.username} Are you sure you want to deactivate your account?</p>
            <button>Deactivate</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
