import React, { useEffect, useState } from 'react';
import {  fetchAllBids, deleteBid } from '../../components/api/ApiClient';

const Bids = () => {
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [bids, setBids] = useState([]);



    const handleDeleteBid = async (bidId) => {
        try {
            await deleteBid(bidId);
            setBids(bids.filter(bid => bid.id !== bidId));
            setAlertMessage('Bid Deleted Successfully.');
            setShowAlert(true);
        } catch (error) {
            console.error('Error deleting bid:', error);
            setAlertMessage('Error Deleting Bid');
            setShowAlert(true);
        }
        setTimeout(() => setShowAlert(false), 5000);
    };

   

    useEffect(() => {

        const fetchBids = async () => {
            try {
                const response = await fetchAllBids();
                setBids(response.data);
            } catch (error) {
                console.error('Error fetching bids', error);
            }
        };
    
        fetchBids();
    }, []);

    return (
        <div className="admin-home-container">
            <div className="admin-bids-container">
                <h2>All Bids</h2>
                <table className="admin-bids-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Phone Number</th>
                            <th>Budget</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bids.map((bid) => (
                            <tr key={bid.id}>
                                <td>{bid.id}</td>
                                <td>{bid.username}</td>
                                <td>{bid.phoneNumber}</td>
                                <td>{bid.budget}</td>
                                <td>
                                    <button className="admin-delete-button" onClick={() => handleDeleteBid(bid.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <hr />
            {showAlert && <div className="instructions-notification-popup">{alertMessage}</div>}
        </div>
    );
};

export default Bids;
