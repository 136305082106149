import React, { useState } from 'react';
import axios from 'axios';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { Link } from 'react-router-dom';
import './LoginSignup.css';
import WTLOGO from "./logo/wt-logo.png";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/security/AuthContext';

function Signup() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();
    const authContext = useAuth();

    // Show or hide password
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);

    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text');
        } else {
            setIcon(eyeOff);
            setType('password');
        }
    }

    async function save(event) {
        event.preventDefault();

        // Check if any field is empty
        if (!username || !email || !mobile || !password || !confirmPassword) {
            setMessage("Please fill in all fields");
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setMessage("Passwords do not match");
            return;
        }

        // Check if terms and conditions are accepted
        if (!termsAccepted) {
            setMessage("Please accept the terms and conditions");
            return;
        }

        // Username validation
        if (username.trim().split(" ").length !== 1) {
            setMessage("Please enter a single name without spaces for username");
            return;
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setMessage("Please enter a valid email address");
            return;
        }

        // Mobile validation
        // const mobileRegex = /^(07|01)\d{8}$/;
        // if (!mobileRegex.test(mobile)) {
        //     setMessage("Incorrect Mobile Number");
        //     return;
        // }

        try {
            const response = await axios.post("https://api.writersturing.com/writersturing/user/register", {
                username,
                email,
                mobile,
                password,
            });

            if (response.data === "User already registered!!") {
                setMessage("User with this email or phone number already exists");
            } else {
                setMessage("Registration Successful! Logging in...");

                // Log in the user automatically
                if (await authContext.login(username, password)) {
                    setMessage("Login Successful! Redirecting in 3 seconds...");
                    setTimeout(() => {
                        navigate(`/user/${username}`);
                    }, 3000); // 3 seconds delay
                } else {
                    setMessage("Registration successful but login failed. Please login");
                }

                // Clear input fields
                setUsername("");
                setEmail("");
                setMobile("");
                setPassword("");
                setConfirmPassword("");
                setTermsAccepted(false);
            }
        } catch (error) {
            console.error(error);
            setMessage("Failed! Please try again");
        }
    }

    return (
        <div className='signup'>
            <div className='form_container'>
                <div className="auth-header">
                    <img src={WTLOGO} alt="" className="wt-logo-img" />
                </div>
                <form>
                    <h3 className='text-center'>Sign Up</h3>
                    <div className='mb-2'>
                        <label htmlFor='username'>User Name</label>
                        <input type='text' placeholder='Enter Username' className='form-control'
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                            required />
                    </div>
                    <div className='mb-2'>
                        <label htmlFor='email'>Email</label>
                        <input type='email' placeholder='Enter Email' className='form-control'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            required />
                    </div>
                    <div className='mb-2'>
                        <label htmlFor='mobile'>Mobile</label>
                        <input type='text' placeholder='Enter Mobile 07 or 01' className='form-control'
                            value={mobile}
                            onChange={(event) => setMobile(event.target.value)}
                            required />
                    </div>
                    <div className='mb-2 relative'>
                        <label htmlFor='password'>Password</label>
                        <input
                            type={type}
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            autoComplete="current-password"
                            className="password-input"
                        />
                        <span className="eye-icon" onClick={handleToggle}>
                            <Icon icon={icon} size={25} />
                        </span>
                    </div>
                    <div className='mb-2 relative'>
                        <label htmlFor='confirmPassword'>Confirm Password</label>
                        <input
                            type={type}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            autoComplete="current-password"
                            className="password-input"
                        />
                        <span className="eye-icon" onClick={handleToggle}>
                            <Icon icon={icon} size={25} />
                        </span>
                    </div>
                    <div className='mb-2'>
                        <input type='checkbox' checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />
                        <label htmlFor='check'>I accept Terms and Conditions </label>
                    </div>
                    {message && <div className='message'>{message}</div>}
                    <div className='d-grid mt-2'>
                        <button type='submit' className='modern-button' onClick={save}>Sign Up</button>
                    </div>
                    <p className='text-center mt-2'>Already have an Account? <Link to='/login' className='ms-2'>Sign In</Link></p>
                </form>
            </div>
        </div>
    )
}

export default Signup;
