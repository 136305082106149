import './App.css';
import TuringApp from './components/webpages/TuringApp';
function App() {
  return (
    <div >
    <TuringApp /> 
    </div>
    
  );
}

export default App;
