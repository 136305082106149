import React, { useState } from 'react';
import './main.css'
import Header from './Header'
import Sidebar from './Sidebar';
import Home from './Home';

function User() {
  const [openSidebar, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const closeSidebar = () => {
    setOpenSidebar(false);
  };

  return (
    <>
      <div className='user-grid-container'>
        <Header OpenSidebar={toggleSidebar} CloseSidebar={closeSidebar}/>
        <Sidebar openSidebarToggle={openSidebar} CloseSidebar={closeSidebar}/>
        <Home/>
      </div>

    </>
  )
}

export default User



