import React from 'react';
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useAuth } from '../../components/security/AuthContext';
import { BsStarFill, BsStar } from 'react-icons/bs';
import { RiUserLine } from 'react-icons/ri';
import './rating.css';

function Rating() {
  const userRating = 1;
  const totalStars = 5;

  const {username} = useParams()

  const authContext = useAuth()


  return (
    <div className='user-mainuser-container'>
    <div className="rating-container">
      <div className="user-icon">
        <RiUserLine className="user-icon-img" />
      </div>
      <div className="stars-container">
        {[...Array(totalStars)].map((_, index) => (
          <span key={index} className="star">
            {index < userRating ? (
              <BsStarFill className="star-icon" />
            ) : (
              <BsStar className="star-icon" />
            )}
          </span>
        ))}
      </div>
      <p className="rating-text"> {username} Your rating is {userRating}/{totalStars}</p>
      <strong className='rating-success'>Success Rate is 100 %</strong>
      <p className="additional-info">
        Your Rating is generated according to online presence, response time and time limit. More information can be used to rate you relating 
        to our Terms and Conditions.
      </p>
    </div>
    </div>
  );
}

export default Rating;
