import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/security/AuthContext';
import './LoginSignup.css';
import WTLOGO from "./logo/wt-logo.png";
import { Link } from 'react-router-dom';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const authContext = useAuth();

    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);

    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text');
        } else {
            setIcon(eyeOff);
            setType('password');
        }
    };

    const handleUsernameChange = (event) => setUsername(event.target.value);
    const handlePasswordChange = (event) => setPassword(event.target.value);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (username.trim().split(" ").length !== 1) {
            setMessage("Please enter a single name without spaces for username");
            return;
        }

        if (!username || !password) {
            setMessage("Please Enter both Username and Password");
            return;
        }

        if (await authContext.login(username, password)) {
            navigate(`/user/${username}`);
        } else {
            setMessage("Incorrect Username and Password");
        }
    };

    return (
        <div className='signup'>
            <div className='form_container '>
                <div className="auth-header">
                    <img src={WTLOGO} alt="" className="wt-logo-img" />
                </div>
                <form onSubmit={handleSubmit}>
                    <h3>Sign In</h3>
                    {message && <div className='message'>{message}</div>}
                    <div>
                        <label htmlFor='username'>Username <strong>(*No spacing)</strong></label>
                        <input
                            type='text'
                            placeholder='Enter Username'
                            name="username"
                            value={username}
                            onChange={handleUsernameChange}
                            required
                        />
                    </div>
                    <div className='relative'>
                        <label htmlFor='password'>Password</label>
                        <input
                            type={type}
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            autoComplete="current-password"
                            className="password-input"
                        />
                        <span className="eye-icon" onClick={handleToggle}>
                            <Icon icon={icon} size={25} />
                        </span>
                    </div>
                    <div>
                        <input type='checkbox' />
                        <label htmlFor='check'>Remember me</label>
                        <p>Forgot <Link to='/forgotpassword'>Password?</Link></p>
                    </div>
                    <div className='d-grid'>
                        <button type='submit' className='modern-button'>Sign In</button>
                    </div>
                    <p className='text-center mt-2'>Don't have an Account? <Link to='/signup' className='ms-2'>Sign Up</Link></p>
                </form>
            </div>
        </div>
    );
}

export default Login;
