import React, { useEffect, useState } from 'react';
import {  fetchAllPayments, fetchAllTraining, deletePayment, deleteTraining, } from '../../components/api/ApiClient';

const Payments = () => {
    
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [payments, setPayments] = useState([]);
    const [training, setTraining] = useState([]);
    


    const handleDeletePayment = async (paymentId) => {
        try {
            await deletePayment(paymentId);
            setPayments(payments.filter(payment => payment.id !== paymentId));
            setAlertMessage('Payment Deleted Successfully.');
            setShowAlert(true);
        } catch (error) {
            console.error('Error deleting payment:', error);
            setAlertMessage('Error Deleting Payment');
            setShowAlert(true);
        }
        setTimeout(() => setShowAlert(false), 5000);
    };


    const handleDeleteTraining = async (trainingId) => {
        try {
            await deleteTraining(trainingId);
            setTraining(training.filter(train => train.id !== trainingId));
            setAlertMessage('Training Payment Deleted Successfully.');
            setShowAlert(true);
        } catch (error) {
            console.error('Error deleting payment:', error);
            setAlertMessage('Error Deleting Payment');
            setShowAlert(true);
        }
        setTimeout(() => setShowAlert(false), 5000);
    };

    

    useEffect(() => {

        const fetchPayments = async () => {
            try {
                const response = await fetchAllPayments();
                setPayments(response.data);
            } catch (error) {
                console.error('Error fetching payments', error);
            }
        };

        const fetchTraining = async () => {
            try {
                const response = await fetchAllTraining();
                setTraining(response.data);
            } catch (error) {
                console.error('Error fetching payments', error);
            }
        };

        fetchPayments();
        fetchTraining();
       
    }, []);

    return (
        <div className="admin-home-container">
            <div className="admin-payments-container">
                <h2>Subscription Payments</h2>
                <table className="admin-payments-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Phone Number</th>
                            <th>Mpesa Code</th>
                            <th>Time Stamp</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => (
                            <tr key={payment.id}>
                                <td>{payment.id}</td>
                                <td>{payment.username}</td>
                                <td>{payment.phoneNumber}</td>
                                <td>{payment.mpesaCode}</td>
                                <td>{payment.time}</td>
                                <td>
                                    <button className="admin-delete-button" onClick={() => handleDeletePayment(payment.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <hr />
            <div className="admin-payments-container">
                <h2>Training Payments</h2>
                <table className="admin-payments-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Phone Number</th>
                            <th>Mpesa Code</th>
                            <th>Time Stamp</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {training.map((train) => (
                            <tr key={train.id}>
                                <td>{train.id}</td>
                                <td>{train.username}</td>
                                <td>{train.phoneNumber}</td>
                                <td>{train.mpesaCode}</td>
                                <td>{train.time}</td>
                                <td>
                                    <button className="admin-delete-button" onClick={() => handleDeleteTraining(train.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <hr />
            
            
            {showAlert && <div className="instructions-notification-popup">{alertMessage}</div>}
        </div>
    );
};

export default Payments;
