import React from 'react'
import { BrowserRouter,Routes, Route} from 'react-router-dom'
import './style.css';
import AuthProvider, {useAuth} from '../security/AuthContext'
import Main from './Main';
import Login from '../../LoginSignup/Login';
import User from '../../dashboard/homemain/User';
import Signup from '../../LoginSignup/Signup';
import Mainavailable from '../../dashboard/jobs/Mainavailable';
import Mainprofile from '../../dashboard/profile/Mainprofile';
import Mainsettings from '../../dashboard/settings/Mainsettings';
import Mainsubscriptions from '../../dashboard/subscriptions/Mainsubscriptions';
import Mainorders from '../../dashboard/orders/Mainorders';
import Mainrating from '../../dashboard/ratings/Mainrating';
import ErrorPage from '../../errorPage/ErrorPage';
import MainDropFileInput from '../../dashboard/uploader/MainDropFileInput';
import LogoutComponent from '../../dashboard/logout/LogoutComponent';
import AdminLogin from '../../admin/AdminLogin';
import Admin from '../../admin/Admin';
import MainTraining from '../../dashboard/tarining/MainTraining';
import ForgotPassword from '../../dashboard/ForgotPassword/ForgotPassword';
import StkPushForm from '../../payment/StkPushForm';
import WithdrawalsPage from '../../dashboard/withrawals/WithdrawalsPage';
import MainPostJobs from '../../admin/postjobs/MainPostJobs';
import MainPayments from '../../admin/payments/MainPayments';
import MainBids from '../../admin/bids/MainBids';
import MainElite from '../../dashboard/goelite/MainElite';

export default function TuringApp() {
    
  function AuthenticatedRoute({children}) {
    const authContext = useAuth()
    
    if(authContext.isAuthenticated)
        return children
}

    return (
    <div >

<AuthProvider>
            <BrowserRouter>
                    <Routes>
                        <Route path='/' element={ <Main /> } />
                        <Route path='/login' element={ <Login /> } />
                        <Route path='/signup' element={ <Signup /> } />
                        <Route path='/forgotpassword' element={ <ForgotPassword /> } />
                        <Route path='/WTadminloginsecret' element={ <AdminLogin />} />
                        <Route path='/stk' element={ <StkPushForm />} />
                        <Route path='/withdraw' element={ <WithdrawalsPage />} />
                        
                        <Route path='/user/:username' element={
                            <AuthenticatedRoute>
                                <User />
                            </AuthenticatedRoute> 
                        } />
                        <Route path='/jobs/:username' element={
                            <AuthenticatedRoute>
                                <Mainavailable /> 
                            </AuthenticatedRoute>
                        } />
                        <Route path='/profile/:username' element={
                            <AuthenticatedRoute>
                                <Mainprofile /> 
                            </AuthenticatedRoute>
                        } />
                        <Route path='/settings/:username' element={
                            <AuthenticatedRoute>
                                <Mainsettings /> 
                            </AuthenticatedRoute>
                        } />
                        <Route path='/purchase-account/:username' element={
                            <AuthenticatedRoute>
                                <Mainsubscriptions /> 
                            </AuthenticatedRoute>
                        } />
                        <Route path='/orders/:username' element={
                            <AuthenticatedRoute>
                                <Mainorders /> 
                            </AuthenticatedRoute>
                        } />
                        <Route path='/training/:username' element={
                            <AuthenticatedRoute>
                                <MainTraining /> 
                            </AuthenticatedRoute>
                        } />
                        <Route path='/rating/:username' element={
                            <AuthenticatedRoute>
                                <Mainrating /> 
                            </AuthenticatedRoute>
                        } />
                        <Route path='/go-elite/:username' element={
                            <AuthenticatedRoute>
                                <MainElite /> 
                            </AuthenticatedRoute>
                        } />
                        <Route path='/upload/:username' element={
                            <AuthenticatedRoute>
                                <MainDropFileInput /> 
                            </AuthenticatedRoute>
                        } />
                            <Route path='/logout' element={
                            <AuthenticatedRoute>
                                <LogoutComponent /> 
                            </AuthenticatedRoute>
                        } />


                        {/* Admin Urls */}

                        <Route path='/dashboard' element={
                            <AuthenticatedRoute>
                                <Admin />
                            </AuthenticatedRoute> 
                        } />


                        <Route path='/post' element={
                            <AuthenticatedRoute>
                                <MainPostJobs />
                            </AuthenticatedRoute> 
                        } />

                        <Route path='/payments' element={
                            <AuthenticatedRoute>
                                <MainPayments />
                            </AuthenticatedRoute> 
                        } />

                        <Route path='/bids' element={
                            <AuthenticatedRoute>
                                <MainBids />
                            </AuthenticatedRoute> 
                        } />



                        <Route path='*' element={<ErrorPage /> } />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
    </div>
  )
}
