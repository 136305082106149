import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../components/security/AuthContext';
import { FaCloudUploadAlt } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import { GiTeacher } from "react-icons/gi";
import { 
    BsBook, BsGrid1X2Fill, BsFillArchiveFill, 
    BsFillGrid3X3GapFill, BsPeopleFill, 
    BsCurrencyDollar, BsStarFill, BsFillGearFill 
} from 'react-icons/bs';
import { FaMedal } from "react-icons/fa6";
import { getUserStatus } from '../../components/api/ApiClient';

function Sidebar({ openSidebarToggle, CloseSidebar }) {
    const { username } = useParams();
    const authContext = useAuth();
    const isAuthenticated = authContext.isAuthenticated;

    const [userStatus, setUserStatus] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            getUserStatus(username)
                .then(response => {
                    setUserStatus(response.data.status);
                })
                .catch(error => console.error('Error fetching user status:', error));
        }
    }, [isAuthenticated, username]);

    function logout() {
        authContext.logout();
    }

    return (
        <aside id="sidebar" className={openSidebarToggle ? "user-sidebar-responsive" : ""}>
            <div className='user-sidebar-title'>
                <div className='user-sidebar-brand'>
                    <BsBook className='user-icon_header' /> Writers Turing
                </div>
                <button className='user-icon user-close_icon' onClick={CloseSidebar}>X</button>
            </div>

            <ul className='user-sidebar-list'>
                {isAuthenticated && (
                    <>
                        <li className='user-sidebar-list-item'>
                            <Link to={`/user/${username}`}>
                                <BsGrid1X2Fill className='user-user-icon' /> Dashboard
                            </Link>
                        </li>
                        <li className='user-sidebar-list-item'>
                            <Link to={`/jobs/${username}`}>
                                <BsFillArchiveFill className='user-user-icon' /> Available Jobs
                            </Link>
                        </li>
                        <li className='user-sidebar-list-item'>
                            <Link to={`/orders/${username}`}>
                                <BsFillGrid3X3GapFill className='user-user-icon' /> Orders
                            </Link>
                        </li>
                        <li className='user-sidebar-list-item'>
                            <Link to={`/upload/${username}`}>
                                <FaCloudUploadAlt className='user-user-icon' /> Upload Order
                            </Link>
                        </li>
                        <li className='user-sidebar-list-item'>
                            <Link to={`/training/${username}`}>
                                <GiTeacher className='user-user-icon' /> Virtual Training
                            </Link>
                        </li>
                        <li className='user-sidebar-list-item'>
                            <Link to={`/profile/${username}`}>
                                <BsPeopleFill className='user-user-icon' /> Profile
                            </Link>
                        </li>
                        <li className='user-sidebar-list-item'>
                            <Link to={`/purchase-account/${username}`}>
                                <BsCurrencyDollar className='user-user-icon' /> Account Purchase
                            </Link>
                        </li>
                        {/* Conditionally render Go Elite option */}
                        {userStatus === 'Premium' && (
                            <li className='user-sidebar-list-item'>
                                <Link to={`/go-elite/${username}`}>
                                    <FaMedal className='user-user-icon' /> Go Elite
                                </Link>
                            </li>
                        )}
                        <li className='user-sidebar-list-item'>
                            <Link to={`/rating/${username}`}>
                                <BsStarFill className='user-user-icon' /> Ratings
                            </Link>
                        </li>
                        <li className='user-sidebar-list-item'>
                            <Link to={`/settings/${username}`}>
                                <BsFillGearFill className='user-user-icon' /> Settings
                            </Link>
                        </li>
                        <li className='user-sidebar-list-item'>
                            <Link to='/login' onClick={logout}>
                                <LuLogOut className='user-user-icon' /> Logout
                            </Link>
                        </li>
                    </>
                )}
            </ul>
        </aside>
    );
}

export default Sidebar;
