import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
import AdminHome from './AdminHome';

function Admin() {
  const [openSidebar, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const closeSidebar = () => {
    setOpenSidebar(false);
  };

  return (
    <>
      <div className='user-grid-container'>
        <AdminHeader OpenSidebar={toggleSidebar} CloseSidebar={closeSidebar}/>
        <AdminSidebar openSidebarToggle={openSidebar} CloseSidebar={closeSidebar}/>
        <AdminHome/>
      </div>

    </>
  )
}

export default Admin



