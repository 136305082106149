import React, { useEffect, useState } from 'react';
import { apiClient, deleteUser, updateUserStatus } from '../components/api/ApiClient';
import { BsPeopleFill, BsSearch } from 'react-icons/bs';
import './admin.css';

const AdminHome = () => {
    const [users, setUsers] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleDeleteUser = async (userId) => {
        try {
            await deleteUser(userId);
            setUsers(users.filter(user => user.user_id !== userId));
            setAlertMessage('User Deleted Successfully.');
            setShowAlert(true);
        } catch (error) {
            console.error('Error deleting user:', error);
            setAlertMessage('Error Deleting User');
            setShowAlert(true);
        }
        setTimeout(() => setShowAlert(false), 5000);
    };

    const handleUpgradeUser = (user) => {
        setSelectedUser(user);
        setShowUpgradePopup(true);
    };

    const handleUpgradeSubmit = async (status) => {
        try {
            await updateUserStatus(selectedUser.mobile, status);
            setUsers(users.map(user => 
                user.user_id === selectedUser.user_id 
                ? { ...user, status, account: status === 'Free' ? 0 : 450 } 
                : user
            ));
            setAlertMessage(`User status updated to ${status} successfully.`);
            setShowAlert(true);
        } catch (error) {
            console.error('Error updating user status:', error);
            setAlertMessage('Error Updating User Status');
            setShowAlert(true);
        }
        setShowUpgradePopup(false);
        setTimeout(() => setShowAlert(false), 5000);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await apiClient.get('https://api.writersturing.com/writersturing/user/all');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users', error);
            }
        };

        fetchUsers();
    }, []);

    const filteredUsers = users.filter(user => 
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="admin-home-container">
            <div className="admin-home-header">
                <h2>Welcome Admin</h2>
                <hr />
            </div>
            <div className="admin-home-cards">
                <div className="admin-card">
                    <div className="admin-card-inner">
                        <h3>Total Users</h3>
                        <BsPeopleFill className="admin-card-icon" />
                    </div>
                    <h1>{users.length}</h1>
                </div>
            </div>
            <hr />
            <div className="admin-users-container">
                <h2>All Users</h2>
                <div className="search-bar">
                    <BsSearch className="search-icon" />
                    <input 
                        type="text" 
                        placeholder="Search by username" 
                        value={searchQuery} 
                        onChange={(e) => setSearchQuery(e.target.value)} 
                    />
                </div>
                <table className="admin-users-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Account Type</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user) => (
                            <tr key={user.user_id}>
                                <td>{user.user_id}</td>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.mobile}</td>
                                <td>{user.status}</td>
                                <td>
                                    <button className="admin-delete-button" onClick={() => handleDeleteUser(user.user_id)}>Delete</button>
                                    <button className="admin-upgrade-button" onClick={() => handleUpgradeUser(user)}>Upgrade</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <hr />
            {showAlert && <div className="instructions-notification-popup">{alertMessage}</div>}
            {showUpgradePopup && (
                <div className="upgrade-popup-container">
                    <div className="upgrade-popup">
                        <h2>Upgrade User</h2>
                        <p>Select a new status for {selectedUser.username}:</p>
                        <button className="upgrade-option-button" onClick={() => handleUpgradeSubmit('Free')}>Free</button>
                        <button className="upgrade-option-button" onClick={() => handleUpgradeSubmit('Premium')}>Premium</button>
                        <button className="upgrade-option-button" onClick={() => handleUpgradeSubmit('Elite')}>Elite</button>
                        <button className="close-popup-button" onClick={() => setShowUpgradePopup(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminHome;
