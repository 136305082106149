import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../components/security/AuthContext';
import { IoAlertCircleOutline } from "react-icons/io5";
import { fetchJobs, submitBid, getUserStatus } from '../../components/api/ApiClient';
import './jobs.css';

function Available() {
  const [showPopup, setShowPopup] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showBidError, setShowBidError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [userStatus, setUserStatus] = useState('');
  const authContext = useAuth();
  const [selectedJob, setSelectedJob] = useState(null);
  const { username } = useParams();

  const [formData, setFormData] = useState({
    subject: '',
    message: '',
    deliveryDate: '',
    phoneNumber: authContext.userDetails?.mobile || ''
  });

  useEffect(() => {
    fetchJobs()
      .then(response => {
        const sortedJobs = response.data.sort((a, b) => b.id - a.id);
        setJobs(sortedJobs);
      })
      .catch(error => console.error('Error fetching jobs:', error));

    getUserStatus(username)
      .then(response => {
        setUserStatus(response.data.status);
      })
      .catch(error => console.error('Error fetching user status:', error));
  }, [username]);

  const togglePopup = (job) => {
    setSelectedJob(job);
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFormSubmit = async () => {
    if (!formData.subject || !formData.message || !formData.deliveryDate) {
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
      return;
    }

    try {
      if (userStatus === 'Free') {
        setShowBidError(true);
        setTimeout(() => setShowBidError(false), 5000);
      } else if (userStatus === 'Premium') {
        setShowBidError(true);
        setTimeout(() => setShowBidError(false), 5000);
      } else if (userStatus === 'Elite') {
        const bidData = {
          ...formData,
          job_title: selectedJob.job_title,
          job_description: selectedJob.job_description,
          employer: selectedJob.employer,
          duration: selectedJob.duration,
          budget: selectedJob.budget,
          username: username,
          phoneNumber: formData.phoneNumber
        };

        await submitBid(bidData);
        closePopup();
        setAlertMessage('Bid submitted successfully!');
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
      }
    } catch (error) {
      console.error('Error submitting bid:', error);
      if (error === 'User has already submitted a bid') {
          closePopup();
          setAlertMessage('You have already submitted a bid.');
          setAlertMessage(true);
      } else {
          closePopup();
          setAlertMessage('An error occurred while submitting the bid.');
      }
      setAlertMessage(true);
      setTimeout(() => setAlertMessage(false), 5000);
    }
  };

  const itemsPerPage = 6;
  const totalPages = Math.ceil(jobs.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage(currentPage === totalPages ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1);
  };

  const slicedJobs = jobs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className='user-mainuser-container'>
      {userStatus === 'Free' ? (
        <div className='notification-bar'>
          <p>
            <IoAlertCircleOutline /> <strong>{username}</strong> To access the tasks, you need to purchase writing account. Click <Link to={`/purchase-account/${username}`}>here</Link> to Purchase.
          </p>
        </div>
      ) : (
        <>
          <h1 className='plans-heading'>Available Jobs</h1>
          <hr />
          <div className="available-jobs">
            {slicedJobs.map(job => (
              <div className="job-card" key={job.id}>
                <h2>{job.job_title}</h2>
                <p>Description: {job.job_description}</p>
                <p>Employer: {job.employer}</p>
                <p>Due Date: {job.duration}</p>
                <p>Budget: {job.budget}</p>
                <button className="bid-button" onClick={() => togglePopup(job)}>Bid</button>
                {showPopup && selectedJob && selectedJob.id === job.id && (
                  <div className="bid-popup">
                    {showError && (<div className="instructions-notification-popup">Please fill the input fields to send</div>)}
                    {showBidError && (<div className="instructions-notification-popup">The task is already assigned. Please Go Elite for new tasks.</div>)}
                    <button className="close-btn" onClick={closePopup}>X</button>
                    <form>
                      <h2>Bid For Order</h2>
                      <label htmlFor="subject">Subject:</label>
                      <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleInputChange} placeholder='Your subject' />

                      <label htmlFor="message">Message:</label>
                      <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleInputChange} placeholder='Message to employer'></textarea>

                      <label htmlFor="delivery-date">Delivery Time (Days)</label>
                      <input type="text" id="delivery-date" name="deliveryDate" value={formData.deliveryDate} onChange={handleInputChange} placeholder='Please indicate delivery time' />
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input type="text" name="phoneNumber" value={authContext.userDetails?.mobile || ''} onChange={handleInputChange} disabled />
                    </form>
                    <hr />
                    <button className='modern-button' onClick={handleFormSubmit}>Send</button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <hr />
          {jobs.length > itemsPerPage && (
            <div className="pagination">
              <button onClick={prevPage} disabled={currentPage === 1}>Prev</button>
              {[...Array(totalPages).keys()].map(pageNum => (
                <button key={pageNum + 1} onClick={() => setCurrentPage(pageNum + 1)}>{pageNum + 1}</button>
              ))}
              <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
          )}
          <hr />
        </>
      )}
       {showAlert && <div className="instructions-notification-popup">{alertMessage}</div>}
    </div>
  );
}

export default Available;
