import React, { useState } from 'react';
import './forgotPassword.css';
import { FaWhatsapp } from 'react-icons/fa'

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setMessage(true);
      setError('');
    } else {
      setError('Please enter a valid email address.');
      setMessage('');
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-card">
        <h2 className="forgot-password-title">Forgot Password</h2>
        {message && <p className="forgot-password-message">A password reset link has been sent to your email address. If it takes long please concat support on  <a href='https://wa.me/+447455356095' target='_blank' rel='noopener noreferrer'>
                      WhatsApp Chat <FaWhatsapp />
                      </a>'</p>}
        {error && <p className="forgot-password-error">{error}</p>}
        <form className="forgot-password-form" onSubmit={handleSubmit}>
          <div className="forgot-password-form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              className={`forgot-password-input ${error ? 'forgot-password-input-error' : ''}`}
            />
          </div>
          <button type="submit" className="forgot-password-submit">Send Reset Link</button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
