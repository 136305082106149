import React from 'react'
import { Link } from 'react-router-dom';
import { LuLogOut } from "react-icons/lu";
import { useAuth } from '../components/security/AuthContext';
import 
{BsBook }
 from 'react-icons/bs'

 import { BsHouseDoor, BsFilePost, BsCardList, BsCreditCard } from 'react-icons/bs';


function AdminSidebar({openSidebarToggle, CloseSidebar}) {


    const authContext = useAuth()

    const isAuthenticated = authContext.isAuthenticated

    function logout() {
        authContext.logout()
    }
  
    
  return (
    <aside id="sidebar" className={openSidebarToggle ? "user-sidebar-responsive": ""}>
        <div className='user-sidebar-title'>
            <div className='user-sidebar-brand'>
                <BsBook   className='user-icon_header'/> ADMIN
            </div>
            <button className='user-icon user-close_icon' onClick={CloseSidebar}>X</button>
        </div>

        <ul className='user-sidebar-list'>
            <li className='user-sidebar-list-item'>
            {isAuthenticated  &&<Link to='/dashboard'>
                <BsHouseDoor className='user-user-icon'/> Home
                </Link>}
            </li>

            <li className='user-sidebar-list-item'>
            {isAuthenticated  &&<Link to='/post'>
                <BsFilePost className='user-user-icon'/> Post Jobs
                </Link>}
            </li>

            <li className='user-sidebar-list-item'>
            {isAuthenticated  &&<Link to='/bids'>
                <BsCardList className='user-user-icon'/> Bids
                </Link>}
            </li>

            <li className='user-sidebar-list-item'>
            {isAuthenticated  &&<Link to='/payments'>
                <BsCreditCard className='user-user-icon'/> Payments
                </Link>}
            </li>

            
            <li className='user-sidebar-list-item'>
            {isAuthenticated && <Link to='/WTadminloginsecret' onClick={logout}>
                    <LuLogOut className='user-user-icon' /> Logout
                </Link>}
            </li>            
        </ul>
    </aside>
  )
}

export default AdminSidebar