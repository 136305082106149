import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../components/security/AuthContext';
import { BsFillBellFill, BsFillBriefcaseFill, BsFillWalletFill, BsFileEarmarkArrowUp, BsClipboardData, BsClipboardCheck, BsCardChecklist } from 'react-icons/bs';
import { getTotalJobs, getUserStatus } from '../../components/api/ApiClient';
import { BiLogoTelegram } from "react-icons/bi";
import './main.css';

function Home() {
  const { username } = useParams();
  const authContext = useAuth();
  const [showNotification, setShowNotification] = useState(true);
  const [totalJobs, setTotalJobs] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [userStatus, setUserStatus] = useState('');

  const handleNotificationDismiss = () => {
    setShowNotification(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchTotalJobs = async () => {
      try {
        const response = await getTotalJobs();
        setTotalJobs(response.data);
      } catch (error) {
        console.error('Error fetching total jobs:', error);
      }
    };

    const fetchUserStatus = async () => {
      try {
        const response = await getUserStatus(username);
        setUserStatus(response.data.status);
        if (response.data.status === 'Free') {
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Error fetching user status:', error);
      }
    };

    fetchTotalJobs();
    fetchUserStatus();
  }, [username]);

  return (
    <div className='user-mainuser-container'>
      {showNotification && (
        <div className='notification-bar'>
          <p><BiLogoTelegram />Join our telegram channel via this link <a href="https://t.me/writersturing">Telegram Link</a></p>
          <button className='notification-dismiss' onClick={handleNotificationDismiss}>X</button>
        </div>
      )}

      {showPopup && (
        <div className='popup'>
          <div className='popup-content'>
            <h2>SERVING YOU BETTER</h2>
            <p>✓ To unlock available task(s) PURCHASE an active account(KES 450). All tasks will be displayed upon account purchase.</p>
            <p>✓ <strong>NOTE:</strong> The activation fee goes straight into your account.</p>
            <p>✓The fee was introduced as a strategy to acquire serious writers and keep off idlers from our platform.</p>
            <button className='popup-close' onClick={handleClosePopup}>ACCEPT</button>
          </div>
        </div>
      )}

      <div className='user-mainuser-title'>
        <h2>Welcome, {username}</h2>
      </div>
      <hr />
      <div className='user-mainuser-cards'>
        <div className='user-card'>
          <div className='user-card-inner'>
            <h3>Available Jobs</h3>
            <BsFillBriefcaseFill className='user-card_icon' />
          </div>
          <h1></h1>
        </div>
        <div className='user-card'>
          <div className='user-card-inner'>
            <h3>Pending Bids</h3>
            <BsFileEarmarkArrowUp className='user-card_icon' />
          </div>
          <h1>0</h1>
        </div>
        <div className='user-card'>
          <div className='user-card-inner'>
            <h3>Orders in progress</h3>
            <BsClipboardData className='user-card_icon' />
          </div>
          <h1>0</h1>
        </div>
        <div className='user-card'>
          <div className='user-card-inner'>
            <h3>Orders in Review</h3>
            <BsCardChecklist className='user-card_icon' />
          </div>
          <h1>0</h1>
        </div>
        <div className='user-card'>
          <div className='user-card-inner'>
            <h3>Completed Orders</h3>
            <BsClipboardCheck className='user-card_icon' />
          </div>
          <h1>0</h1>
        </div>
        <div className='user-card'>
          <div className='user-card-inner'>
            <h3>Notifications</h3>
            <BsFillBellFill className='user-card_icon' />
          </div>
          <h1>0</h1>
        </div>
        <div className='user-card'>
          <div className='user-card-inner'>
            <h3>Pending Payment</h3>
            <BsFillWalletFill className='user-card_icon' />
          </div>
          <h1>Ksh.0</h1>
        </div>
        <div className='user-card'>
          <div className='user-card-inner'>
            <h3>Account Balance</h3>
            <BsFillWalletFill className='user-card_icon' />
          </div>
          <h1>Ksh. {authContext.userDetails?.account || ''}</h1>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Home;
