import About from './About';
import Footer from './Footer';
import JobSections from './JobSections';
import Navbar from './Navbar';
import Header from './Header';
import Carousel from './testimonials/Carousel';
import VirtualTraining from './virtualtraining/VirtualTrainining';
import './style.css';

function Main() {
  return (
    <div>
        <Navbar />
        <Header />
        <About />
        <JobSections />
        <VirtualTraining />
        <Carousel/>
        
        <Footer />
    </div>
  );
}

export default Main;
