import React, { useState, useEffect } from 'react';
import './orders.css';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../components/security/AuthContext'; // Assuming useAuth hook provides authentication context

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Table.css";

function Orders() {
  const [bids, setBids] = useState([]);
  const { username } = useParams();
  const { userDetails } = useAuth();

  const [orders] = useState([
    
  ]);

  useEffect(() => {
    // Fetch bids based on user's phone number
    if (userDetails?.mobile) {
      fetch(`https://api.writersturing.com/api/bids/by-phone?phoneNumber=${userDetails.mobile}`)
        .then(response => response.json())
        .then(data => setBids(data))
        .catch(error => console.error('Error fetching bids:', error));
    }
  }, [userDetails]);

  const createData = (job_title, employer, budget, status) => {
    return { job_title, employer, budget, status };
  };

  const rows = bids.map((bid, index) => createData(bid.job_title, bid.employer, bid.budget, 'Pending'));

  const makeStyle = (status) => {
    if (status === 'Approved') {
      return {
        background: 'rgb(145 254 159 / 47%)',
        color: 'green',
      };
    } else if (status === 'Pending') {
      return {
        background: '#ffadad8f',
        color: 'red',
      };
    } else {
      return {
        background: '#59bfff',
        color: 'white',
      };
    }
  };

  return (
    <div className='user-mainuser-container'>
      <hr />
      <h1 className='order-heading'>Job Bids</h1>
      <div className='note'>
        <strong><p>Note 1:</p></strong>
        <p>Awarded Bids will be pushed to Orders, rejected bids from clients will disappear automatically.
        </p>
        <strong><p>Note 2:</p></strong>
          <p>More resources and files on the task will be shared by CLIENTS after being awarded as order.</p>
      </div>
      <hr />
      <div className="Table">
        <TableContainer component={Paper} style={{ boxShadow: "0px 13px 20px 0px #80808029" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Job Title</TableCell>
                <TableCell align="left">Employer</TableCell>
                <TableCell align="left">Budget</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {rows.map((row, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align="left">
                    <span className="status" style={makeStyle(row.status)}>{row.status}</span>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.job_title}
                  </TableCell>
                  <TableCell align="left">{row.employer}</TableCell>
                  <TableCell align="left">{row.budget}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* Awarded Orders section */}
      <div className="orders-container">
        <hr />
        <h1 className='order-heading'>Awarded Orders</h1>
        <hr />
        {orders.length === 0 ? (
          <p className="no-orders">{username} you have no orders today. Please place Bid for Orders.</p>
        ) : (
          <div className="order-list">
            {orders.map(order => (
              <div className="order-card" key={order.id}>
                <div className="order-info">
                  <p className='order-paragraph'><span className="order-information">Order ID:</span> {order.id}</p>
                  <p className='order-paragraph' ><span className="order-information">Subject:</span> {order.subject}</p>
                  <p className='order-paragraph'><span className="order-information">Topic:</span> {order.topic}</p>
                  <p className='order-paragraph'><span className="order-information">Pages:</span> {order.pages}</p>
                  <p className='order-paragraph'><span className="order-information">Budget (KES):</span> {order.budget}</p>
                  <p className='order-paragraph'><span className="order-information">Duration:</span> {order.duration}</p>
                  <p className='order-paragraph'><span className="order-information">Status:</span> {order.status}</p>
                  <p className='order-paragraph'><span className="order-information">Budget:</span> {order.status}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Orders;
