export const items = [
    {
        status: "inactive",
        imgSrc: "https://cdn-icons-png.flaticon.com/512/147/147144.png",
        name: "Charlie",
        position: "Web Developer",
        quote: "Through Writers Turing platform, I secured a lucrative web development project that challenged and honed my skills. Thank you, Writers Turing, for the amazing opportunity!"
    },
    {
        status: "inactive",
        imgSrc: "https://cdn-icons-png.flaticon.com/512/147/147144.png",
        name: "David",
        position: "Data Analyst", 
        quote: "Writers Turing platform introduced me to an exciting data analysis project where I made impactful insights. Grateful for the exposure and learning experience!"
    },
    {
        status: "inactive",
        imgSrc: "https://cdn-icons-png.flaticon.com/512/147/147144.png",
        name: "Emily",
        position: "Marketing Specialist",
        quote: "Thanks to Writers Turing platform, I collaborated on a cutting-edge marketing campaign that garnered widespread attention. It was an enriching experience!"
    },
    {
        status: "active",
        imgSrc: "https://cdn-icons-png.flaticon.com/512/147/147144.png",
        name: "Frank",
        position: "Software Engineer",
        quote: "Writers Turing connected me with a groundbreaking software development project that pushed my boundaries and expanded my skill set. Truly grateful for the opportunity!"
    },
    {
        status: "inactive",
        imgSrc: "https://cdn-icons-png.flaticon.com/512/147/147144.png",
        name: "Grace",
        position: "UX/UI Designer",
        quote: "Through Writers Turing platform, I contributed to an innovative UX/UI design project that prioritized user experience. It was a fulfilling journey!"
    },
    {
        status: "inactive",
        imgSrc: "https://cdn-icons-png.flaticon.com/512/147/147144.png",
        name: "Alice",
        position: "Software Developer",
        quote: "Through Writers Turing platform I was linked to a world-class programming project which I worked for a private client and got retained by the client after delivering good work. Thanks to Writers Turing."
    },
    {
        status: "inactive",
        imgSrc: "https://cdn-icons-png.flaticon.com/512/147/147144.png",
        name: "Bob",
        position: "Content Writer",
        quote: "Through Writers Turing platform, I landed a fantastic opportunity to craft engaging content for a global brand. The experience was phenomenal, and I look forward to more projects!"
    },
    {
        status: "inactive",
        imgSrc: "https://cdn-icons-png.flaticon.com/512/147/147144.png",
        name: "Eve",
        position: "Graphic Designer",
        quote: "Writers Turing connected me with a prestigious design project that allowed me to showcase my creativity and expertise. Kudos to Writers Turing for the seamless experience!"
    }
];

export default items;
