import React, { useState } from 'react';
import { BsJustify,  } from 'react-icons/bs';

function AdminHeader({ OpenSidebar }) {

  return (
    <div className='user-userbar'>
      <div className='user-menu-icon'>
        <BsJustify className='main-header-user-icon' onClick={OpenSidebar} />
      </div>

      <div className='user-userbar-right'>
       <h2>Writers Turing Admin</h2>
      </div>
    </div>
  );
}

export default AdminHeader;
