import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { apiClient } from "../api/ApiClient";
import { executeJwtAuthenticationService, fetchUserDetailsService } from "../api/AuthenticationApiService";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [username, setUsername] = useState(null);
    const [token, setToken] = useState(null);
    const [userDetails, setUserDetails] = useState(null);

    const login = async (username, password) => {
        try {
            const response = await executeJwtAuthenticationService(username, password);

            if (response.status === 200) {
                const jwtToken = 'Bearer ' + response.data.token;

                setAuthenticated(true);
                setUsername(username);
                setToken(jwtToken);

                 // Save token to local storage
                 localStorage.setItem('token', jwtToken);
                 localStorage.setItem('username', username);

                apiClient.interceptors.request.use((config) => {
                    config.headers.Authorization = jwtToken;
                    return config;
                });

                const userDetailsResponse = await fetchUserDetailsService(username);
                setUserDetails(userDetailsResponse.data);

                return true;
            } else {
                logout();
                return false;
            }
        } catch (error) {
            logout();
            return false;
        }
    };

    const logout = () => {
        setAuthenticated(false);
        setToken(null);
        setUsername(null);
        setUserDetails(null);
        localStorage.removeItem('token');
        localStorage.removeItem('username');
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const storedUsername = localStorage.getItem('username');

        if (storedToken && storedUsername) {
            setAuthenticated(true);
            setUsername(storedUsername);
            setToken(storedToken);

            apiClient.interceptors.request.use((config) => {
                config.headers.Authorization = storedToken;
                return config;
            });

            fetchUserDetailsService(storedUsername)
                .then((response) => {
                    setUserDetails(response.data);
                })
                .catch(() => {
                    logout();
                });
        }
    }, []);


    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, username, token, userDetails }}>
            {children}
        </AuthContext.Provider>
    );
}