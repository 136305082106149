import React, { useState } from 'react';
import {useParams} from 'react-router-dom'
import { useAuth } from '../../components/security/AuthContext'
import { BsGearFill, BsPersonFill, BsEnvelopeFill, BsLockFill, BsQuestionCircleFill } from 'react-icons/bs';
import './settings.css';

function Settings() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const {username } = useParams()

    const authContext = useAuth()

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleDeleteConfirmation = (confirm) => {
    if (confirm) {
      // Delete account logic
      console.log('Account deleted');
    }
    setShowPopup(false);
  };

  return (
    <div >
    <div className="settings-container">
      <h1 className="settings-title">Settings</h1>
      <hr />
      <div className="options-container">
        <div className="option" onClick={() => handleOptionClick('Profile')}>
          <BsPersonFill className="option-icon" />
          <span className="option-text">Profile</span>
        </div>
        <div className="option" onClick={() => handleOptionClick('Notifications')}>
          <BsEnvelopeFill className="option-icon" />
          <span className="option-text">Notifications</span>
        </div>
        <div className="option" onClick={() => handleOptionClick('Privacy')}>
          <BsLockFill className="option-icon" />
          <span className="option-text">Privacy</span>
        </div>
        <div className="option" onClick={() => handleOptionClick('User Account')}>
          <BsGearFill className="option-icon" />
          <span className="option-text">Account</span>
        </div>
        <div className="option" onClick={() => handleOptionClick('Help')}>
          <BsQuestionCircleFill className="option-icon" />
          <span className="option-text">Help & Support</span>
        </div>
      </div>
      <hr />
      <div className="delete-account">
        <button className="delete-btn" onClick={() => handleOptionClick('Delete Account')}>
          Delete Account
        </button>
      </div>
      
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-btn" onClick={closePopup}>×</button>
            <h2 className="popup-title">{selectedOption}</h2>
            {selectedOption === 'Profile' && (
              <div className="profile-settings">
                <label htmlFor="real-names">Your Username</label>
                <input type="text" id="real-names" value= {username}  disabled />
                <label htmlFor="change-username">Change Username</label>
                <input type="text" id="change-username" />
                <label htmlFor="profile-picture">Profile Picture</label>
                <div className="profile-picture-container">
                  <div className="profile-picture-placeholder"></div>
                  <input type="file" id="profile-picture" />
                </div>
                <label htmlFor="bio">Bio</label>
                <textarea id="bio"></textarea>
                <button className="save-btn">Save</button>
              </div>
            )}
            {selectedOption === 'Notifications' && (
              <div className="notifications-settings">
                <form>
                  <label htmlFor="enable-notifications">Enable Notifications</label>
                  <input type="checkbox" id="enable-notifications" />
                  <label htmlFor="enable-sms">Enable SMS Notifications</label>
                  <input type="checkbox" id="enable-sms" />
                  <label htmlFor="enable-email">Enable Email Notifications</label>
                  <input type="checkbox" id="enable-email" />
                </form>
                <button className="save-btn">Save</button>
              </div>
            )}
            {selectedOption === 'Privacy' && (
              <div className="privacy-settings">
                <label htmlFor="share-profile">Share Profile</label>
                <select id="share-profile">
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </select>
                <button className="save-btn">Save</button>
              </div>
            )}
            {selectedOption === 'User Account' && (
              <div className="account-settings">
                <label htmlFor="username">Username</label>
                <input type="text" id="username" value= {username}  disabled />
                <label htmlFor="full-name">Full Name</label>
                <input type="text" id="full-name" value="" disabled />
                <label htmlFor="phone">Phone Number</label>
                <input type="text" id="phone" value={authContext.userDetails?.mobile || ''} disabled  />
                <label htmlFor="email">Email</label>
                <input type="email" id="email" value={authContext.userDetails?.email || ''} disabled />
                <label htmlFor="address">Address</label>
                <input type="text" id="address" value="" disabled />
                <h3>Account Information</h3>
                <label htmlFor="account-type">Account Type</label>
                <input type="text" id="account-type" value={authContext.userDetails?.status || ''} disabled />
                <button className="save-btn">Save</button>
              </div>
            )}
            {selectedOption === 'Help' && (
              <div className="help-settings">
                <input type="text" placeholder="How can we help you?" />
                <div className="help-options">
                  <button className="help-option">Pricing</button>
                  <button className="help-option">Payment</button>
                  <button className="help-option">Global Employment</button>
                  <button className="help-option">Contractor Employment</button>
                  <button className="help-option">Bidding Help</button>
                  {/* Add more relevant help options */}
                </div>
              </div>
            )}

             {selectedOption === 'Delete Account' && (
        <div className="popup">
          <div className="popup-content delete-popup">
            <button className="close-btn" onClick={closePopup}>×</button>
            <h2 className="popup-title">Delete Account</h2>
            <p>{username} Are you sure you want to delete your account?</p>
            <div className="delete-buttons">
              <button className="confirm-btn" onClick={() => handleDeleteConfirmation(true)}>Yes</button>
              <button className="cancel-btn" onClick={() => handleDeleteConfirmation(false)}>No</button>
            </div>
          </div>
        </div>
      )}
          </div>
        </div>
      )}

     
    </div>

    </div>
  );
}

export default Settings;
