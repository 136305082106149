import React from 'react';
import './error.css';

function ErrorPage() {
  return (
    <div className="error-page">
      <div className="error-content">
        <h1 className="error-heading">Oops! Something went wrong.</h1>
        <p className="error-message">We couldn't find the page you're looking for.</p>
        <p className="error-suggestion">Please check the URL and try again.</p>
        <button className="error-btn" onClick={() => window.history.back()}>Go Back</button>
      </div>
    </div>
  );
}

export default ErrorPage;
